import $ from "jquery";

import("swiper/scss");
import("swiper/scss/navigation");

(async () => {
  const { default: Swiper } = await import("swiper");
  const { Navigation } = await import("swiper/modules");
  $(".product-categories--type-1 .swiper").each(function () {
    new Swiper(this, {
      modules: [Navigation],
      slidesPerView: 2,
      spaceBetween: 20,
      navigation: {
        nextEl: $(this).siblings(".navigation").find(".nav--next")[0],
        prevEl: $(this).siblings(".navigation").find(".nav--prev")[0],
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1280: {
          slidesPerView: 5,
          spaceBetween: 20,
        },
        1440: {
          slidesPerView: 6,
          spaceBetween: 44,
        },
      },
    });
  });
})();
